<template>
  <div>
    <div class="pb-2">
      <h3>Date Block Management</h3>
      <small class="form-text text-muted">
        Define the Date Ranges for which Special Pricing and seasons could be
        applied.
      </small>
      <hr />
    </div>
    <div v-if="!loading">
      <FormErrorAlert
        v-if="errors.length > 0"
        :errors="errors"
        :formRef="this.$refs.dateBlockForm"
      />
      <ValidationObserver ref="dateBlockForm">
        <form @submit.prevent="saveBlock">
          <div class="row">
            <div class="col-md-2 col-sm-12">
              <ValidationProvider
                rules="required"
                name="Block Type"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="blockType"
                >
                  Block Type
                  <span class="error">*</span>
                </label>
                <select
                  class="form-control form-control-lg"
                  id="blockType"
                  v-model="blockTypeId"
                  v-bind="ariaInput"
                  disabled
                >
                  <option
                    v-for="type in blockTypes"
                    :key="type.id"
                    :value="type.id"
                    >{{ type.name }}</option
                  >
                </select>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
            </div>
            <div class="col-md-2 col-sm-12">
              <ValidationProvider
                rules="required"
                name="Day Name"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="dayName"
                >
                  Day Name
                  <span class="error">*</span>
                </label>
                <select
                  class="form-control form-control-lg"
                  id="dayName"
                  v-model="dayNameId"
                  v-bind="ariaInput"
                >
                  <option
                    v-for="day in dayNames"
                    :key="day.id"
                    :value="day.id"
                    >{{ day.name }}</option
                  >
                </select>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
            </div>
            <div class="col-md-2 col-sm-12">
              <ValidationProvider
                rules="required"
                name="Year"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="selectedYear"
                >
                  Year
                  <span class="error">*</span>
                </label>
                <select
                  class="form-control form-control-lg"
                  id="selectedYear"
                  v-model="year"
                  v-bind="ariaInput"
                >
                  <option v-for="year in years" :key="year" :value="year">{{
                    year
                  }}</option>
                </select>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
            </div>
            <div class="col-md-2 col-sm-12">
              <DateInput
                vid="startDate"
                rules="isMonthDay|required"
                name="Start Date"
                id="startDate"
                v-model="startDate"
                format="MM/DD"
                placeholder="MM/DD"
                mask="##/##"
              />
            </div>
            <div class="col-md-2 col-sm-12">
              <DateInput
                vid="endDate"
                rules="isMonthDay|required"
                name="End Date"
                id="endDate"
                v-model="endDate"
                :initialDate="initialDate"
                :dateDisabledFn="minDepartureDate"
                format="MM/DD"
                placeholder="MM/DD"
                mask="##/##"
              />
            </div>
            <div class="col-md-2 col-sm-12">
              <button
                class="btn btn-primary float-right mt-4"
                type="submit"
                v-show="!dateBlockId"
              >
                ADD NEW BLOCK +
              </button>
              <button
                class="btn btn-cancel float-right mt-4"
                type="button"
                v-show="dateBlockId"
                @click="clearForm"
              >
                CANCEL
              </button>
              <button
                class="btn btn-primary float-right mt-4 mr-2"
                type="submit"
                v-show="dateBlockId"
              >
                SAVE
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <b-table
        ref="table"
        striped
        :fields="fields"
        :items="dateBlocks"
        stacked="md"
        small
        class="mt-4"
        bordered
        sort-icon-left
      >
        <template v-slot:cell(blockType)="data">{{
          getBlockTypeName(data.item.dateBlockTypeId)
        }}</template>
        <template v-slot:cell(dayName)="data">{{
          getDateBlockDayName(data.item.dateBlockDayId)
        }}</template>
        <template v-slot:cell(id)="data">
          <button
            class="btn btn-primary btn-sm mx-1"
            @click="editBlock(data.item.id)"
          >
            Modify
          </button>
        </template>
      </b-table>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminTenantService from "@/services/admin/AdminTenantService.js";

export default {
  name: "AdminDateBlockManagement",
  title: "Admin - Date Block Management",
  components: {
    DateInput,
    FormErrorAlert,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      errors: [],
      dateBlocks: [],
      dayNames: [],
      blockTypes: [],
      dateBlockId: null,
      blockTypeId: "1",
      dayNameId: "",
      year: "",
      startDate: "",
      endDate: "",
      fields: [
        { key: "blockType", sortable: true },
        { key: "dayName", sortable: true },
        { key: "year", sortable: true },
        { key: "startDate", sortable: true },
        { key: "endDate", sortable: true },
        { key: "id", label: "Actions/Options" }
      ]
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      const service = new AdminTenantService(this.tenantId);
      const dateBlocks = service.getDateBlocks();
      const blockTypes = service.getDateBlockTypes();
      const dayNames = service.getDateBlockDays();
      await Promise.allSettled([dateBlocks, blockTypes, dayNames]).then(
        responses => {
          this.dateBlocks = responses[0].value.data;
          this.blockTypes = responses[1].value.data;
          this.dayNames = responses[2].value.data;
        }
      );
      this.loading = false;
    },
    clearForm() {
      this.dateBlockId = null;
      this.blockTypeId = "1";
      this.dayNameId = "";
      this.year = "";
      this.startDate = "";
      this.endDate = "";
    },
    async saveBlock() {
      if (this.blockExists() && !this.dateBlockId) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Date Block already exists for this Year.",
          layer: "admin"
        });
        return;
      }
      this.$refs.dateBlockForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.dateBlockForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.dateBlockForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          const block = {
            id: this.dateBlockId,
            dateBlockTypeId: +this.blockTypeId,
            dateBlockDayId: this.dayNameId,
            year: this.year.toString(),
            startDate: this.startDate,
            endDate: this.endDate
          };
          this.loading = true;
          const service = new AdminTenantService(this.tenantId);
          const response = await service.saveDateBlock(block);
          if (response?.statusCode === "Success") {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-success",
              message: "Date Block has been saved.",
              layer: "admin"
            });
            const dateBlocks = await service.getDateBlocks();
            this.dateBlocks = dateBlocks.data;
            this.loading = false;
            this.clearForm();
          } else {
            this.loading = false;
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    editBlock(id) {
      window.scrollTo(0, 0);
      const block = this.dateBlocks.find(x => x.id === id);

      this.dateBlockId = id;
      this.blockTypeId = "1";
      this.dayNameId = block.dateBlockDayId;
      this.year = block.year;
      this.startDate = block.startDate;
      this.endDate = block.endDate;
    },
    async deleteBlock(id) {
      const service = new AdminTenantService(this.tenantId);
      const response = await service.deleteDateBlock(id);
      if (response?.statusCode === "Success") {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-success",
          message: "Date Block has been deleted.",
          layer: "admin"
        });
        const dateBlocks = await service.getDateBlocks();
        this.dateBlocks = dateBlocks.data;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    getBlockTypeName(id) {
      return this.blockTypes.find(x => x.id === id)?.name;
    },
    getDateBlockDayName(id) {
      return this.dayNames.find(x => x.id === id)?.name;
    },
    blockExists() {
      const block = this.dateBlocks.find(
        x => x.dateBlockDayId === +this.dayNameId && +x.year === this.year
      );
      return !!block;
    },
    minDepartureDate(ymd) {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").isSameOrAfter(ymd, "day");
      } else {
        return moment().isSameOrAfter(ymd, "day");
      }
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    years() {
      const years = [];
      const now = new Date();
      for (let i = 0; i < 31; i++) {
        years.push(now.getFullYear() + i);
      }
      return years;
    },
    blockType() {
      return this.blockTypes.find(x => x.id === +this.blockTypeId)?.name;
    },
    dayName() {
      return this.dayNames.find(x => x.id === +this.dayNameId)?.name;
    },
    initialDate() {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style>
.text-muted {
  color: #6b727b !important;
}
</style>
